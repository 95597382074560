jQuery(document).ready(function ($) {
  // primary nav
  $('.nav-primary').accessibleNav({
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });

  //Nav Functionality - Start
  $('.nav-primary .nav-toggle').click(function () {
    $('.nav-primary .ul1').toggle();
    $('.nav-toggle .fa-bars').toggle();
    $('.nav-toggle .fa-times').toggle();
    $('header').toggleClass('fixed');
    $('body').toggleClass('fixed');
  });

  $('.nav-primary .nav-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.nav-primary .ul1').toggle();
      $('.nav-toggle .fa-bars').toggle();
      $('.nav-toggle .fa-times').toggle();
      $('header').toggleClass('fixed');
      $('body').toggleClass('fixed');
    }
  });
  //Nav Functionality - END

  //Search Functionality - Start
  $('.search-toggle').click(function () {
    $('.search-box').toggleClass('active');
    $('.search-toggle .fa-search').toggleClass('active');
    $('.search-toggle .fa-times').toggleClass('active');
  });

  $('.search-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.search-box').toggle();
      $('.search-toggle .fa-search').toggle();
      $('.search-toggle .fa-times').toggle();
    }
  });
  //Nav Functionality - END

  //Scroll to Top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $(".scroll-to-top").css("opacity", "1");
    } else {
      $(".scroll-to-top").css("opacity", "0");
    }
  });
  $(".scroll-to-top").click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  //Open external links in new tab
  $("a[href^='https://']").not('a[href*=lldss]').attr("target", "_blank");
  $("a[href^='http://']").not('a[href*=lldss]').attr("target", "_blank");
});